import type { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

import scrollbar from './foundations/scrollbar';
import addressEntity from './globals/address-entity';
import getDefaultTransitionProps from './utils/getDefaultTransitionProps';

const global = (props: StyleFunctionProps) => ({
  body: {
    position: 'relative',
    bg: mode('white', '#080808')(props),
    backgroundImage: 'url(/static/home_background.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    ...getDefaultTransitionProps(),
    '-webkit-tap-highlight-color': 'transparent',
    'font-variant-ligatures': 'no-contextual',
    _before: {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      backgroundImage: 'url(/static/zero-one-bg.svg)',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center',
      height: '100%',
      width: '618px',
      zIndex: -1,
    },
    _after: {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%) scaleX(-1)',
      backgroundImage: 'url(/static/zero-one-bg.svg)',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      height: '100%',
      width: '618px',
      zIndex: -1,
    },
  },
  mark: {
    bgColor: mode('green.100', 'green.800')(props),
    color: 'inherit',
  },
  'svg *::selection': {
    color: 'none',
    background: 'none',
  },
  form: {
    w: '100%',
  },
  ...scrollbar(props),
  ...addressEntity(props),
});

export default global;
