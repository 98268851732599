import { getEnvValue } from './utils';

export default Object.freeze({
  reCaptcha: {
    siteKey: getEnvValue('NEXT_PUBLIC_RE_CAPTCHA_APP_SITE_KEY'),
  },
  cookieBot: {
    cookieBotKey: getEnvValue('NEXT_PUBLIC_APP_COOKIE_BOT_KEY'),
  },
});
